@import '../../../styles/base/variables';
@import '../../../styles/vendor/icons';
@import '../../../styles/base/mixins';

$headerHeight: 8.625rem;

:global(.backwardbutton-hidden) {
  visibility: hidden;
  height: 0;
}

:global(.nordics-backwardbutton-visible) {
  visibility: visiblle;
  height: auto;
}

.navigationContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.navBorder {
  height: 3px;
  background-color: var(--gb-brand-primary);
  margin-top: 2.1875rem;
  transition: all $transition-faster $transition-timing-ease-out;
  will-change: left, width;
  position: absolute;
  bottom: 0;

  &.bgNordics {
    height: 5px;
    background-color: var(--gb-brand-primary-light);
  }

  // state
  :global(.pageHeader--reduced) & {
    margin-top: 1.25rem;
  }
}

.navRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  .locatorPin {
    & > span {
      cursor: pointer;

      svg {
        fill: var(--gb-brand-primary-light);
      }
    }

    &:before {
      content: none;
    }
  }
}

:global(#page-header) {
  .iconWrapper {
    :global(.c-search) {
      flex: none;
    }
  }
}

.iconWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  button {
    svg {
      fill: var(--gb-brand-primary);
      width: 1.25rem;
    }
  }
}

.close {
  position: absolute;
  top: 1.875rem;
  right: 0.9375rem;
  z-index: 300;
  color: $gb_grey_600;
  line-height: 1;

  > i::before {
    font-size: 1.25rem;
    color: $gb_grey_600;
  }

  &:hover {
    color: $gb_grey_600;
  }
}

.mainNavigation {
  display: flex;
  flex-direction: row;
  padding: 1.25rem;

  @include screen-max-md(){
    height: $header-height-mobile;
    padding:0;
  }

  :global(.c-search) {
    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.75rem;
      height: 2.75rem;

      @include screen-md {
        margin-right: -0.75rem;
      }
    }
  }

  @include screen-md {
    padding: 2.5rem 0;
    will-change: max-height;
    transition: max-height $transition-fast $transition-timing-ease-out;
    max-height: 6.25rem;
  }

  // state
  :global(.pageHeader--reduced) & {
    padding: 1.5rem 0;
    max-height: 3.75rem !important;

    @include screen-md {
      max-height: 4.325rem !important;
    }

    &.isTouch {
      @include screen-md {
        max-height: 3.75rem !important;
      }
    }
  }
}

.itemsWrapper {
  display: flex;
  flex-flow: wrap;

  :global(.nord) &,
  :global(.kolo) &,
  :global(.twyford) & {
    > div {
      &:focus-visible {
        border: 1px solid var(--gb-brand-primary);
      }
    }
  }

  & > .navigationItem {
    padding-right: 1.875rem;
    font-size: 0.9375rem;

    &:first-of-type {
      padding-left: 1.875rem;
    }

    @include screen-lg {
      padding-right: 2.5rem;
      &:first-of-type {
        padding-left: 2.5rem;
      }
    }

    :global(.nord) &,
    :global(.kolo) &,
    :global(.twyford) & {
      > a {
        &:focus-visible {
          border: 2px solid var(--gb-brand-primary);
          outline: 2px solid transparent;
        }
        &:hover {
          border-width: 2px;
          outline: 2px solid transparent;
        }
      }

      > a,
      > button {
        border: 2px solid transparent;
      }
    }

    > a,
    > button {
      color: $gb_black;
      will-change: color;
      transition: color $transition-fast $transition-timing-ease-out;

      &:focus,
      &:hover,
      &.active {
        text-decoration: none;

        &:focus-visible {
          border: 2px solid var(--gb-brand-primary);
          outline: 2px solid transparent;
        }
      }

      &.activeNordics {
        text-decoration: none;
        &:focus-visible {
          border: 2px solid var(--gb-brand-primary-light);
          outline: 2px solid transparent;
        }
      }
    }
  }
}

.flyoutKeep {
  .flyout {
    max-height: 100vh !important;
    border-top: 1px solid $gb_grey_150;
  }
}

:global(.flyout--open),
:global(.flyout--border) {
  :local(.flyout) {
    border-top: 1px solid $gb_grey_150;
  }
}

.navigationItem {
  ul {
    display: none;
    list-style: none;
    padding: 0;
  }

  .flyout {
    display: block;
    position: absolute;
    top: $headerHeight;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 10;
    overflow: hidden;
    max-height: 0;
    background: linear-gradient(to right, $gb_grey_050 30%, $gb_white 30%);
    will-change: max-height, transition-delay;
    transition: max-height $transition-fast $transition-timing-ease-out;

    &.flyoutTransition {
      transition: max-height $transition-fast $transition-timing-ease-out;
    }

    &.flyoutTransitionDelay {
      transition-delay: $transition-fast;
    }

    &.flyoutDown {
      max-height: 100vh;
    }

    & > .activeClickPath {
      position: relative;
      left: 0;
      width: 33%;
      height: 100%;
      background-color: $gb_grey_050;
      z-index: 11;
    }

    a {
      width: 100%;
      text-align: left;

      &:hover {
        text-decoration: none;
      }

      &:focus-visible {
        border: 2px solid var(--gb-brand-primary);
        outline: 1px solid transparent;
      }
    }

    &.nordicLinks {
      &:focus-visible {
        border: 1px solid var(--gb-brand-primary-light);
      }
    }

    a,
    button:not(:global(.backward-button)) {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid transparent;
      padding: 0.4375rem 0 0.5rem;
      color: $gb_black;
      will-change: color;
      transition: color $transition-fast $transition-timing-ease-out;

      // state
      &:hover {
        color: var(--gb-brand-primary-light);
        border-bottom: 1px solid var(--gb-brand-primary);
      }

      &.active {
        color: var(--gb-brand-primary);
        border-bottom: 1px solid var(--gb-brand-primary);
      }

      &.close {
        padding: 0;
      }
    }

    button {
      // state
      &:hover,
      &.active {
        border-bottom: 1px solid var(--gb-brand-primary);
      }

      &:focus-visible {
        border: 2px solid var(--gb-brand-primary);
        outline: 1px solid transparent;
      }

      &.close:hover {
        border-bottom: 1px solid transparent;
      }
    }

    // state
    :global(.pageHeader--reduced) & {
      top: 4.324375rem;
    }
  }

  .column {
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin-left: -1.25rem;
    opacity: 0;
    will-change: opacity, margin-left, transition-delay;
    transition: all $transition-fast $transition-timing-ease-out;

    &.columnIn {
      opacity: 1;
      margin-left: 0;
      transition-delay: $transition-faster;
    }

    &.columnOut {
      opacity: 0;
    }

    &.columnInNoDelay {
      transition-delay: 0s !important;
    }
  }

  :global(.navcolumn-0),
  :global(.navcolumn-0-nordics) {
    > :local(.column) {
      opacity: 0;
      transition: all $transition-fast $transition-timing-ease-out;
      will-change: opacity, margin-left;

      &.columnIn {
        opacity: 1;
        margin-left: 0;
        transition-delay: $transition-fast !important;
      }
    }

    :local(.scrollIndicator) {
      background-color: $gb_grey_050;
    }
  }

  .activeClickPath {
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    padding-top: 3.4375rem;

    margin-top: 0;
    background-color: $gb_white;
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;

    @include screen-lg {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &:global(.navcolumn-0) {
      left: 0;
      width: calc(100% / 3);
      background-color: $gb_grey_050;
    }

    .scrollItem {
      max-height: 100%;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      :global(.nord) &,
      :global(.kolo) &,
      :global(.twyford) & {
        > p {
          color: $gb_grey_650;
        }
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      > ul {
        > li {
          margin: 0;
          padding: 0;
        }
      }

      h4 {
        text-transform: none;
        margin-bottom: 1.25rem;
      }

      .navigationItem {
        p {
          left: 0;
        }
      }
    }

    & > div > div > ul {
      display: block;
    }
  }

  :global(.navcolumn-1),
  :global(.navcolumn-2) {
    :local(.scrollIndicator) {
      background-color: $gb_white;
    }
  }

  :global(.navcolumn-2) {
    button {
      i {
        display: none;
      }
    }
  }

  :global(.navcolumn-2-nordics) {
    z-index: 1;
    left: 0;

    button {
      i {
        display: none;
      }
    }

    ul > li > a:hover {
      background-color: $gb_grey_050;
    }
  }

  :global(.navcolumn-1-nordics),
  :global(.navcolumn-2-nordics) {
    :local(.scrollIndicator) {
      background-color: $gb_white;
    }

    ul > li > a:hover {
      background-color: $gb_grey_050;
      border-bottom: 1px solid transparent !important;
    }

    ul > li > div > button:hover {
      background-color: $gb_grey_050;
      border-bottom: 1px solid transparent !important;
    }

    //BackButton
    button:not(:global(.backward-button)) {
      &:hover,
      &:active {
        background-color: $gb_grey_050;
        border-bottom: 1px solid transparent !important;
      }
    }

    :global(.backward-button) {
      transform: translateX(-1rem);
      margin-bottom: 1.25rem;
      > span {
        width: auto;
        &:first-child {
          width: 2.5rem;
        }
      }
    }
  }
}

.navigationItemIcon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;

  i {
    line-height: 1.5rem;
  }
}

.scrollIndicator {
  background-color: $gb_white;
  width: calc(100% - 1.5625rem);
  display: block;
  height: 4rem;
  position: absolute;
  bottom: 0;

  @include screen-lg {
    width: calc(100% - 2.5rem);
  }

  > span {
    width: 100%;
    position: absolute;
    animation: scrollIndicatorAnimation;
    animation-iteration-count: infinite;
    animation-duration: 0.6s;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;

    &:before {
      @include web20-icon('chevron-large-down');
      position: absolute;
      left: 0;
      bottom: 0;
    }

    > span {
      &:before {
        @include web20-icon('chevron-large-down');
        position: absolute;
        left: 0;
        bottom: 0.375rem;
        opacity: 0.4;
      }
    }
  }
}

.campusMain {
  flex: 1;
}

.campusMeta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
}

@keyframes scrollIndicatorAnimation {
  0% {
    bottom: 1rem;
  }
  100% {
    bottom: 1.3125rem;
  }
}

.nordWidth {
  .flyout {
    button {
      border: 2px solid transparent;

      &:hover {
        border: 2px solid transparent;
      }
    }
  }

  &:global(.grid-container),
  :global(.grid-container) {
    max-width: 90rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    z-index: 30;
  }

  .navigationItem {
    .activeClickPath {
      padding: 2rem 0.5rem 0 1.5rem;

      .column p {
        margin: 0 0 1.25rem 0rem;
        line-height: 1.25rem;
        text-transform: none;
      }

      .scrollItem {
        > ul > li {
          min-height: 2.5rem;
          > a {
            span {
              border: none;
            }
          }

          :global(.c-tpp-area) {
            > button {
              margin: 0;
              padding: 0 0.5rem;
              > a {
                color: inherit;
                font-size: inherit;
                font-weight: normal;
                &:before {
                  content: none;
                  color: inherit;
                }
                > span {
                  border: none;
                }
              }
            }
          }
        }

        > ul > li > div > button,
        > ul > li > button,
        > ul > li > div > a,
        > ul > li > a {
          border: 1px solid transparent;
          padding: 7px 0 7px 0.5rem;
          transform: none;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: normal;
          color: $gb_black;
          height: auto;
          transition: none;
          align-items: center;
          // necessary so that ouline - see focus-visible state - is not cut off.
          left: -1px;
          position: relative;

          > i {
            width: 2.5rem;
            min-width: 2.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.activeNord {
            color: var(--gb-brand-primary-light);
            border-bottom: 1px solid var(--gb-brand-primary-light);

            > span {
              color: var(--gb-brand-primary-light);
            }
          }

          &:hover {
            text-decoration: none;

            > span {
              text-decoration: none;
              border: none;
            }
          }

          &:focus-visible {
            text-decoration: none;
            border: 1px solid var(--gb-brand-primary);
            outline: 1px solid var(--gb-brand-primary);

            > span {
              text-decoration: none;
              border: none;
            }
          }

          &:active {
            text-decoration: none;
            color: white;
            background-color: rgb(61, 61, 61);

            > span {
              text-decoration: none;
              border: none;
            }
          }
        }

        > ul > li > div > a {
          &:before {
            content: none;
          }
        }
      }
    }
    .scrollItem {
      > ul {
        margin-top: 0;
        padding-left: 0.5rem;

        > li {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        > li > a {
          &::before {
            display: none;
            font-weight: normal;
            color: $gb_black;
          }
        }
      }

      .scrollIndicator {
        margin-left: 0.5rem;
      }

      > button {
        margin-bottom: 1.25rem;
      }

      > p {
        margin-bottom: 1.25rem;
      }
    }

    .flyout {
      display: none;

      &.flyoutTransition {
        display: initial;
      }
    }
  }

  .mainNavigation {
    height: 6.25rem;
    max-height: 25rem;
    padding: 0;
  }

  :global(.pageHeader--reduced) & {
    .mainNavigation {
      padding: 0;
      max-height: 4rem;
    }

    .navigationItem {
      .flyout {
        top: 4rem;

        :global(.nord) &,
        :global(.kolo) &,
        :global(.twyford) & {
          top: 4.325rem;
        }
      }
    }
  }

  :global(.pageHeader--reduced.nord.isTouch) & {
    .navigationItem .flyout {
      top: 6.125rem;
    }
  }

  &#{&}#{&}#{&} .itemsWrapper {
    gap: 2rem;
    margin-left: 2rem;

    & > .navigationItem {
      font-size: 1rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      > a,
      > button {
        height: 2.5rem;
        padding: 0;
        transform: none;
      }

      > div {
        > a,
        > button {
          height: 2.5rem;
          padding: 0;
          transform: none;
        }
      }

      a {
        font-size: 1rem;
        color: $gb_black;
        font-weight: normal;

        &:hover {
          text-decoration: none;

          > span {
            text-decoration: none;
            border-bottom: none;
          }
        }

        &:active {
          background-color: transparent;
          border-color: transparent;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .close,
  :global(.c-search__close) {
    top: 1rem;
    right: 0.75rem;
  }
}
